module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["LibreBaskerville"],"urls":["/assets/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-4/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"showCaptions":true,"linkImagesToOriginal":false,"loading":"lazy","markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"","enableIdentityWidget":true,"publicPath":"admin","htmlTitle":"All in 60 CMS","htmlFavicon":"static/icons/manifest-icon-192.png","includeRobots":true,"logo_url":"https://twilightscapes.com/assets/tw-logo-white.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-52307112-1"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hytron Manufacturing","short_name":"Hytron","start_url":"/?user_mode=app","description":"Hytron Manufacturing","background_color":"#111","lang":"en","theme_color":"#111","display":"standalone","icon":"static/assets/logo.svg","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/static/siteimages/manifest-icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/static/siteimages/manifest-icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ec0d8991ee1c7d65a71521a001ff8630"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
